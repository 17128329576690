import { render, staticRenderFns } from "./return-points-view.vue?vue&type=template&id=71c5ebf5&scoped=true&"
import script from "./return-points-view.vue?vue&type=script&lang=js&"
export * from "./return-points-view.vue?vue&type=script&lang=js&"
import style0 from "./return-points-view.vue?vue&type=style&index=0&id=71c5ebf5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c5ebf5",
  null
  
)

export default component.exports