import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.isShow,"persistent":"","max-width":"600px"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.title))])]),_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCardText,[_c(_vm.messageComponent,{tag:"component",attrs:{"placeholder":_vm.placeholder,"rules":_vm.rules,"items":_vm.options,"show-by":_vm.selectShowBy},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.handleCloseClick}},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")]),_c(VBtn,{attrs:{"type":"submit","color":"blue darken-1","text":""}},[_vm._v(" "+_vm._s(_vm.saveText)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }