<template>
  <div class="return-points-view">
    <p v-if="!returnPoints.length">
      Список пуст
    </p>

    <template v-else>
      <rcc-button @click="selectAllDcs">Выбрать все</rcc-button>
      <rcc-button class="ml-5" @click="unselectAllDcs" isOutlined >Отменить все</rcc-button>

      <rcc-checkboxes-group
        :value="selectedReturnPoints"
        choose-by="id"
        show-by="name"
        :options="returnPoints"
        class="checkboxes-grid"
        @input="updateSelectedPointsList"
      />
    </template>
  </div>
</template>

<script>
import RccCheckboxesGroup from 'Components/controls/rcc-checkboxes-group'
import RccButton from 'Components/controls/rcc-button'

export default {
  name: 'RccReturnPointsView',

  components: {
    RccCheckboxesGroup,
    RccButton
  },

  props: {
    networkId: {
      type: Number,
      default: null
    },

    selectedReturnPoints: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      returnPoints: []
    }
  },

  computed: {
    allReturnPointsIds() {
      return this.returnPoints.map(({ id }) => id)
    }
  },

  mounted() {
    this.getReturnPoints()
  },

  methods: {
    getReturnPoints() {
      if (!this.networkId) {
        return
      }

      this.$apiMethods.returnPoints.dcList({ network_id: this.networkId })
        .then(points => {
          this.returnPoints = points.filter(({ available }) => available)
        })
    },

    updateSelectedPointsList(points) {
      this.$emit('selected-points-change', points)
    },

    selectAllDcs() {
      const newPointsList = Array.from(new Set([...this.selectedReturnPoints, ...this.allReturnPointsIds]))
      this.updateSelectedPointsList(newPointsList)
    },

    unselectAllDcs() {
      const newPointsList = this.selectedReturnPoints.filter(point => !this.allReturnPointsIds.includes(point))

      this.updateSelectedPointsList(newPointsList)
    }
  }
}
</script>

<style lang="scss" scoped>
.return-points-view {
  margin-top: 10px;

  .checkboxes-grid {
    margin-top: 20px;
    width: 90%;

    & > div {
      width: 33%;
    }

    @media screen and (max-width: 1200px) {
      width: 100%;

      & > div {
        width: 50%;
      }
    }

    @media screen and (max-width: 650px) {
      & > div {
        width: 100%;
      }
    }
  }
}
</style>
